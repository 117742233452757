import type { Personalizations } from '../../lib/hooks/usePersonalizations'
import { firstFromCollection } from '../collection'
import type { PageFragment } from '../fragments/PageFragment.graphql'
import { makeQueryFunction, makeUseQueryHook } from '../functions'
import { EMPTY_PAGE } from '../placeholders'
import { GetPageDocument } from '../queries/GetPage.graphql'

/**
 * Possible values of the "type" field of the page block.
 */
export type PageSectionFragmentType = NonNullable<
    PageFragment['sections']
>['items'][0]['type']

export type PageHookOptions = {
    /** Slug of the page to retrieve */
    slug: string

    /** Whether to run the query */
    enabled?: boolean

    /** Whether to include the banner in the query result. */
    includeBanner?: boolean

    /** Whether to include the page body in the query result. Doesn't include banner */
    includeBody?: boolean

    /** Optional personalizations to apply to the query result. */
    personalizations?: Personalizations
}

/**
 * Returns personalized contents for a page for given slug.
 */
export const usePage = ({
    slug,
    includeBanner = true,
    includeBody = true,
    enabled = true,
}: PageHookOptions) => {
    const result = useGetPage({
        variables: { slug, includeBanner, includeBody },
        pause: !enabled,
    })

    const page = firstFromCollection(result.data?.pages, EMPTY_PAGE)

    return {
        ...result,
        exists: !!result.data?.pages?.total,
        page,
    }
}

const useGetPage = makeUseQueryHook({
    query: GetPageDocument,
})

export const getPage = makeQueryFunction({
    query: GetPageDocument,
})
