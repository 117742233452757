import type { DateMessage } from '@getstep/proto/common'
import addMinutes from 'date-fns/addMinutes'
import format from 'date-fns/format'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import isFuture from 'date-fns/isFuture'
import isThisYear from 'date-fns/isThisYear'
import isValid from 'date-fns/isValid'
import subMinutes from 'date-fns/subMinutes'

import { TEXT } from './text'

/**
 * Formats a date to a December 31, 2012 string
 * omits year and shortens the month, if same year as now, Dec 12th
 */
export function formatRelativeDate(date: Date | number): string {
    return isThisYear(date)
        ? formatDate(date, 'MMM do')
        : formatDate(date, 'MMM yyyy')
}

/**
 * Formats a date into "Expires at ..." string,
 * returns nothing if date is not in the future
 */
export function formatExpiresAt(date: Date | number): string {
    if (!isFuture(date)) return ''
    return formatDistanceToNow(date, {
        addSuffix: true,
        includeSeconds: true,
    })
}

export function formatDate(date: Date | number, fmt: DateFormat) {
    return format(date, fmt)
}

export const dateFromProtoDate = (d: DateMessage) => {
    // CommonProto.Date.month is 1 indexed while Date.month is 0 indexed.
    return new Date(d.year, d.month - 1, d.day)
}

export function isValidDate(date: unknown): date is Date {
    return isValid(date)
}

/**
 * Adds the timezone offset to the date
 * @param date
 * @returns
 */
export const formatTimezone = (date: Date) => {
    const offset = date.getTimezoneOffset()

    return Math.sign(offset) !== -1
        ? addMinutes(date, offset)
        : subMinutes(date, Math.abs(offset))
}

type DateFormat =
    | 'MMMM d, yyyy'
    | 'MMM do'
    | 'MMM yyyy'
    | 'MM / dd / yyyy'
    | 'MM / yy'
/**
 * Returns the day of the week name, 0 indexed
 */
export const getDayOfWeekName = (day: number) => {
    const days = [
        TEXT.schedule.day.sunday,
        TEXT.schedule.day.monday,
        TEXT.schedule.day.tuesday,
        TEXT.schedule.day.wednesday,
        TEXT.schedule.day.thursday,
        TEXT.schedule.day.friday,
        TEXT.schedule.day.saturday,
    ]
    return days[day]
}
